<template>
  <div>
    <button class="button button4" @click="submit">
      <img src="@/assets/stripe-logo.png" alt="Pagar com Stripe" width="90px" />
    </button>
  </div>
</template>
<script>
import { createStripeSession } from "@/services/external/paypal-service";

export default {
  name: "StripeButton",
  props: {
    idPlan: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      publishableKey: `pk_test_51OvOhTGhNgEsP79k50llLRnCh0btLaIzCyuW810GlBeEjIlgaVbUTJlcsTTOfBTIn6a9G3MlOqYVZSF0baNx26eJ00ZZlRrGBO`,
      successURL: "https://web.appbio.com.br/my-subscription",
      cancelURL: "https://web.appbio.com.br/my-subscription",
      clientReferenceId: "",
      loading: false,
      lineItems: [
        {
          price: "price_1OvOkwGhNgEsP79kW4mgNHKp",
          quantity: 1,
        },
      ],
      user: {},
    };
  },
  created() {
    //  this.loadUser();
  },
  methods: {
    async submit() {
      // You will be redirected to Stripe's secure checkout page
      //  this.clientReferenceId = `${user.mail}@@${idPlan}`;
      //  this.$refs.checkoutRef.redirectToCheckout();
      const response = await createStripeSession(this.idPlan);
      // redirect
      window.open(response.data.url, "_blank").focus();
    },
    async loadUser() {
      try {
        let response = await getByToken();
        this.user = response.data;
      } catch (error) {
        this.$errorHandler(error);
      }
    },
  },
};
</script>
<style>
.button {
  border: 1px solid #635bff;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 2px;
  cursor: pointer;
  border-radius: 4px;
  width: 300px;
  height: 46px;
}
</style>
